
export default {
    props: {
        dataSource: Array,
        freight: String
    },
    computed: {
        columns() {
            const unit = JSON.parse(localStorage.getItem("all_unit") || '{"declared_value_currency":"USD","item_weight_unit":"lbs"}');

            return [{
                    key: "index",
                    width: 48,
                    customRender: ({
                        index
                    }) => index + 1
                },
                {
                    key: "description",
                    dataIndex: "description",
                    title: "物品名称"
                },
                {
                    key: "pieces",
                    dataIndex: "pieces",
                    title: "单位",
                    width: 100
                },
                {
                    key: "declared_value",
                    dataIndex: "declared_value",
                    title: "单价",
                    width: 100,
                    customRender: ({
                        text
                    }) => text + " " + unit.declared_value_currency
                },
                {
                    key: "weight",
                    dataIndex: "weight",
                    title: "重量",
                    width: 100,
                    customRender: ({
                        text
                    }) => text + " " + unit.item_weight_unit
                },
                {
                    key: "count",
                    dataIndex: "count",
                    title: "总额(单位*单价+运费)",
                    width: 180,
                    customRender: ({
                        text,
                        index
                    }) => {
                        if (this.$refs.table?.data.length - 1 == index) {
                            return Number(text) + Number(this.freight || 0) + " " + unit.declared_value_currency
                        } else {
                            return text + " " + unit.declared_value_currency
                        }

                    }
                }
            ]
        },
        list() {
            let arr = this.dataSource.length > 0 ? this.dataSource.filter(i => i.description) : [];
            let newArr = [];
            let obj = {};
            arr.forEach(i => {

                if (!obj[i.description]) obj[i.description] = Object.assign({}, i);
                else {
                    const newObj = obj[i.description];
                    newObj.weight = Number(newObj.weight) + Number(i.weight || 0);
                    newObj.pieces = Number(newObj.pieces) + Number(i.pieces || 0);
                    newObj.declared_value = Number(newObj.declared_value) + Number(i.declared_value || 0);
                }
            })
            let result = {
                description: "合计",
                pieces: 0,
                declared_value: 0,
                weight: 0,
                count: 0
            };

            Object.keys(obj).forEach(key => {
                newArr.push(obj[key]);
                obj[key].count = Number(obj[key].declared_value) * Number(obj[key].pieces)
                result.pieces += Number(obj[key].pieces)
                result.declared_value += Number(obj[key].declared_value)
                result.weight += Number(obj[key].weight)
                result.count += Number(obj[key].count)
            });

            newArr.length > 0 && newArr.push(result);

            return newArr
        }
    }
}
