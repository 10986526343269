
  import {
    UploadOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    LoadingOutlined,
    CloseCircleOutlined
  } from '@ant-design/icons-vue';
  import {
    ref,
    watch
  } from "vue"
  import moment from 'moment';
  import AddressBook from '@/views/user/basic/address_book/compontent/simple.vue';
  import address from '@/utils/address';
  import OrderInfo from "./components/order-info.vue"
  import AddressGroup from "@/views/admin/order/components/address-group.vue"
  import AddressForm from "@/views/admin/order/components/address-form.vue"
  import ItemsTable from "./components/items-table.vue"
  import PackageUpload from "./components/package-upload.vue"

  import {
    reloadPageTab
  } from "@/utils/page-tab-util"
  import _ from "lodash"
  import apiCancel from "@/utils/api-cancel";
  import AnalyzePostalCode from "@/components/Address/hook/analyze-postalcode.js"
  import cachePostCode from "@/views/admin/order/hook/cachePostCode"
  import itemHook from "@/views/admin/order/hook/itemHook"
  import {
    isValidate
  } from "@/views/admin/order/hook/order-validate"
  import {
    OpernationWatch
  } from "@/utils/common"
  import {
    computed
  } from "vue";

  let test_rates_post = new apiCancel();

  // 通过对象去重
  function uniByObj(arr, cb) {
    let newArr = [];
    let uniArr = [];
    for (var i = 0; i < arr.length; i++) {
      const item = arr[i];
      const obj = cb(item);
      const uniCode = JSON.stringify(Object.assign({}, obj))

      if (!uniArr.some(item => item == uniCode)) {
        newArr.push(item);
        uniArr.push(uniCode);
      } else {
        const index = uniArr.findIndex(item => item == uniCode);
        newArr[index].packages_qty += item.packages_qty
      }
    }

    return newArr;
  }

  export default {
    // name: "OrderBuild",
    components: {
      UploadOutlined,
      PlusCircleOutlined,
      MinusCircleOutlined,
      LoadingOutlined,
      CloseCircleOutlined,
      AddressBook,
      AddressGroup,
      OrderInfo,
      ItemsTable,
      AddressForm,
      PackageUpload
    },
    provide() {
      return {
        form: computed(() => this.form),
        packages: computed({
          get: () => {
            return this.form.packages
          },
          set: (val) => {
            this.form.packages = val
          }
        }),
        test_rates: this.test_rates,
        onFoucsControl: computed({
          get: () => this.onFoucsControl,
          set: (val) => this.onFoucsControl = val
        })
      }
    },
    data() {
      return {
        // 运输方式
        shipmethods: [],
        // Freight Class
        freight_class: [],
        // 显示地址簿
        showBook: false,
        // 测试费用加载状态
        rates_loading: false,
        //
        rates_timer: 0,
        // 测算费用结果
        rates_result: {},
        // 当前选择的渠道
        rates_current_ship_method_id: 0,
        // 错误代码
        error_code: '',
        // 加载状态
        loading: false,
        // 查询地址加载状态
        query_address_loading: false,
        // 自定义显示内容
        custom_content: '',
        // 监听器控制
        stop: null,
        // 结果窗口
        resultModal: {
          // 是否显示
          visible: false,
          // 状态控制
          status: 'info',
          download_loading: false,
          void_loading: false,
          title: '',
          subTitle: ''
        },
        // 焦点控制器
        onFoucsControl: false,
        // 地址名称监听者开关
        watchAddressName: null,
        // 打开导入包裹
        showImportPackage: false,
        // 发货地址是否隐藏
        shipper: true,
        // Reference 必填
        order_ref: true,
        // 隐藏Freight class
        order_freightclass: true,
        // 币种
        currency: {},
      };
    },
    watch: {
      'form.address': {
        handler() {
          if (this.address.shipper_address_id) localStorage.setItem('cache_shipper_address_id', JSON.stringify(this
            .address.shipper_address_id));

          this.onFoucsControl = true;

          if (!this.$refs.address_form || !this.$refs.packages_form) {
            return;
          }

          if (!this.validate_package) {
            this.form_validate().then().catch(() => {
              this.rates_result.ship_method_id = null;
            })
          }
        },
        immediate: true,
        deep: true
      },
      'form.packages.length': {
        handler() {
          this.onFoucsControl = true;

          this.$nextTick(() => {
            let els = document.querySelectorAll('input');
            for (let i = 0; i < els.length; i++) {
              const element = els[i];
              element.addEventListener('change', () => {
                if (isValidate(this.$refs.address_form.getFieldsValue(), this.rules) && !this
                  .validate_package) {
                  this.test_rates()
                }
              }, true)

            }
          })
        },
        immediate: true,
        deep: true
      },
      rates_current_ship_method_id: {
        handler(newVal, oldVal) {

          if (oldVal && newVal !== this.rates_result.ship_method_id) {
            this.onFoucsControl = true;
            this.test_rates()
          }
        }
      },
      items: {
        handler() {
          this.onFoucsControl = true;
        },
        deep: true
      }
    },
    computed: {
      table_shipmethods() {
        return [{
            title: this.$t('ship_method.fields.carrier_code'),
            dataIndex: 'carrier_code',
            width: 80
          },
          {
            title: this.$t('ship_method.fields.name'),
            dataIndex: 'name',
            width: 250
          },
          {
            title: this.$t('ship_method.fields.again'),
            dataIndex: 'again',
            width: 120
          },
          {
            title: this.$t('ship_method.fields.description'),
            dataIndex: 'description',
            className: "desc-max-width",
            slots: {
              customRender: 'desc'
            }
          },
          {
            title: this.$t('account.fields.fuel_surcharges'),
            dataIndex: 'fuel_surcharges',
            width: 100,
            align: 'center',
            customRender: ({
              text
            }) => `${(text * 100).toFixed(2)}%`
          },
          {
            title: this.$t('user_order.fields.weight'),
            dataIndex: 'total_weight',
            width: 100,
            align: 'center',
            customRender: ({
              record
            }) => {
              return `${record.total_weight} ${this.renderUnit(this.obj_all_unit, 'weight_unit')}`
            }
          },
          {
            title: this.$t('单价'),
            dataIndex: 'unitPrice',
            width: 100,
            align: 'center',
            customRender: ({
              record
            }) => {
              return `${((record.amount / record.total_weight)*this.currency.exchange_rate).toFixed(2)} RMB`
            }
          },
          {
            title: this.$t('user_order.fields.charge'),
            dataIndex: 'amount',
            width: 120,
            align: 'center',
            customRender: ({
              text,
              record
            }) => {
              return text + record.currency
            }
          },
          {
            title: this.$t('share.operation'),
            key: 'action',
            width: 100,
            align: 'center',
            fixed: "right",
            slots: {
              customRender: 'action'
            }
          }
        ].filter(c => {
          if (this.$setting.env == 'fhjy.lbl.morelink56.com' && c.dataIndex == 'fuel_surcharges') {
            return false
          }
          if (this.$setting.env == 'first-star.lbl.morelink56.com' && c.dataIndex == 'fuel_surcharges') {
            return false
          }
          if (this.$setting.env != 'szbyze.com' && c.dataIndex == 'unitPrice') {
            return false
          }
          return true
        })
      },
      packages() {
        return this.form.packages
      },
      extra_packages() {
        let result = {};

        this.packages.forEach((item, index) => {
          for (var k in item) {
            result[`${index}.${k}`] = `${item[k]}`;
          }
        });

        return result;
      },
      ship_methods_result() {
        return (this.rates_result.ship_methods || []).sort(this.sort_rates);
      },
      charges() {
        return this.rates_result.charges || [];
      },
      total_cahrge() {
        var value = 0;

        this.charges.forEach(a => value += a.discount_value);

        return value.toFixed(2);
      },
      /* 验证是否通过 */
      validate_package() {
        return this.packages.some(item => {
          const {
            unit: {
              dims_unit,
              weight_unit
            }
          } = item
          Object.assign(item, {
            dims_unit,
            weight_unit
          });
          return !item.length || !item.width || !item.height || !item.weight
        })
      },
      // 需要限高
      need_height() {
        return this.ship_methods_result.length > 6
      },
      // 是否激活提交
      isSubmitActive() {
        if (!this.rates_result.ship_methods) {
          return false
        }

        const obj = this.rates_result.ship_methods.find(item => this.rates_result.ship_method_id == item.id);
        return obj && obj.active
      }
    },
    mounted() {

      this.$http.get(`/admin/system_config/list/basic`).then(res => {
        if (res.data.code === 0) {
          var data = Object.assign({}, res.data.data);
          this.shipper = data.shipper === '1';
          this.order_ref = data.order_ref === '1';
          this.order_freightclass = data.order_freightclass === '1';
          if (this.order_ref) {
            const rules = [{
              "required": true,
              "message": "必填",
              "type": "string",
              "trigger": "blur"
            }]
            this.rules.global_reference1 = rules
            this.rules.global_reference2 = rules
          }
        }
      })
      this.isUpdate = this.$route.query.id !== undefined;
      this.query();

      if (this.isUpdate) {
        this.$store.dispatch('theme/tabSetTitle', {
          fullPath: this.$route.fullPath,
          title: this.$route.query.rebuild ? this.$t('重制') : this.$t('share.update_title')
        });

        this.loadOrder(this.$route.query.id);
      } else {
        this.addPackage();
      }

      // 初始化监听事件
      const _this = this;

      this.watchAddressName = new OpernationWatch(this.$watch, 'form.address.name', () => {
        if (/^[A-Za-z0-9]{4}$/.test(_this.address.name)) {
          //自动填充FBA地址
          _this.queryFbaAddress(_this.address.name);
          // 停止邮编监听事件
          _this.stop && _this.stop();
        }
      })

      this.watchAddressName.startWatch();
    },
    methods: {
      /**
       * 包裹添加ref
       */
      packageMap() {
        this.form.packages.forEach(p => {
          if (this.form.global_reference1) {
            p.reference1 = this.form.global_reference1
          }
          if (this.form.global_reference2) {
            p.reference2 = this.form.global_reference2
          }
        });
      },
      loadOrder(id) {
        this.loading = true;
        this.$http.get(`/user/order/get_order_info/${id}`).then(res => {
          this.loading = false;

          if (res.data.code === 0) {
            var data = res.data.data;

            this.form = data;
            this.form.id = data.id;
            this.form.customer_reference = data.customer_reference;

            if (data.packages[0]) {
              this.form.global_reference1 = data.packages[0].reference1;
              this.form.global_reference2 = data.packages[0].reference2;
            }

            this.form.ship_date = moment().format('YYYY-MM-DD');

            this.watchAddressName.stopWatch();

            this.form.address = Object.assign(data.address, {
              shipper_address_id: data.shipper_address.id
            });

            this.watchAddressName.startWatch();

            this.form.packages = data.packages.map((item) => {
              item.unit = Object.assign(this.obj_all_unit, {
                dims_unit: item.dims_unit,
                weight_unit: item.weight_unit
              });
              return item
            });
            // 去重
            this.form.packages = uniByObj(this.form.packages, (item) => {
              return {
                length: item.length,
                width: item.width,
                height: item.height,
                weight: item.weight,
                freight_class: item.freight_class
              }
            });

            this.form.items = data.items.map((item) => {
              item.unit = Object.assign(this.obj_all_unit, {
                item_weight_unit: item.weight_unit,
                declared_value_currency: item.declared_value_currency,
                manufacture_country_code: item.manufacture_country_code
              });
              return item
            });
            this.rates_current_ship_method_id = data.ship_method_id;

            this.$nextTick(() => this.test_rates(true));
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          console.log(e);
          this.loading = false;
          this.$message.error(e.message);
        });
      },
      query() {
        Promise.all([
          this.$http.get('/user/shipper/simple_list'),
          this.$http.get('/user/order/freight_class'),
          this.$http.get(`/system_config/content/order_page_content`),
          this.$http.get(`/admin/currency/list?sort=sort&order=asc&page=1&=15`),
        ]).then(res => {
          if (res[0].data.code === 0) {
            this.shippers = res[0].data.data;

            if (this.shippers.length > 1) {
              const cache_id = localStorage.getItem("cache_shipper_address_id") || JSON.stringify(0);
              const get_shipper = this.shippers.find(item => item.id == JSON.parse(cache_id)) || this.shippers[
                0];

              this.form.address.shipper_address_id = get_shipper.id
            } else if (this.shippers.length == 1) {
              this.form.address.shipper_address_id = this.shippers[0].id;
            } else {
              this.form.address.shipper_address_id = 0
            }

            // 定制判断
            if (this.$setting.env == 'fhjy.lbl.morelink56.com'||this.$setting.env == 'first-star.lbl.morelink56.com') this.form.address.shipper_address_id = 0
            if (this.$setting.env == 'hygj.lbl.morelink56.com'|| this.$setting.env.includes("hyfba.com.cn")) this.form.address.shipper_address_id = 0
          } else {
            this.$message.error(res[0].data.msg);
          }

          if (res[1].data.code === 0) {
            this.freight_class = res[1].data.data;
          } else {
            this.$message.error(res[1].data.msg);
          }

          if (res[2].data.code === 0) {
            this.custom_content = res[2].data.data;
          }
          if (res[3].data.code === 0) {
            this.currency = res[3].data.data[1];
          }
        }).catch(e => {
          this.$message.error(e.message);
        });
      },
      /**
       * 提交订单
       */
      submit() {
        var rm = this.resultModal;

        this.form_validate()
          .then(() => {
            this.$refs.info_form.validate().then(() => {
              if (this.system_config.allow_customs_data != '1' || !this.allow_customs_data) {
                this.form.items = [];
              }

              this.renderItemsData()

              rm.visible = true;
              rm.back_list = false;
              rm.status = 'info';
              rm.title = this.$t('user_order.tips.order_creating');
              rm.subTitle = this.$t('user_order.tips.waiting');

              var postData = Object.assign(this.form, {
                ship_method_id: this.rates_current_ship_method_id,
                ship_user_importer_id: this.form.ship_user_importer_id || 0,
                shipper_address_id: this.address.shipper_address_id,
              });

              if (this.$route.query.rebuild === '1') {
                delete postData.id;
              }

              postData.shipper_address_id = !this.shipper ? (postData.shipper_address_id + "") : '0';
              postData.ship_date = moment().format('YYYY-MM-DD');

              this.packageMap();

              this.$http.post('/user/order/submit', postData).then(res => {
                if (res.data.code === 0) {
                  if (res.data.data.extend == 1) {
                    rm.status = 'success';
                    rm.title = this.$t('user_order.tips.submit_success');
                    rm.subTitle = res.data.data.po_number;
                    return
                  }

                  if (res.data.data.order_realtime_label) {
                    rm.title = this.$t('user_order.tips.order_label_creating');
                    rm.subTitle = this.$t('请耐心等待，该操作大约所需1~2分钟');

                    this.form.id = res.data.data.id;
                    this.form.po_number = res.data.data.po_number;

                    let time = 0;
                    var createLabel = () => {
                      this.$http.get(`/user/order/create_label/${res.data.data.id}`).then(res2 => {
                        if (res2.data.code === 0) {
                          if (time > 1000 * 30) {
                            rm.status = 'error';
                            rm.title = this.$t('user_order.tips.create_label_error');
                            rm.subTitle = null;
                            rm.error_message = "订单提交成功，获取面单失败请稍后在获取";
                            return
                          }

                          if (res2.data.data.ready === '0') {
                            time += 2000;
                            setTimeout(createLabel, 2000);
                          } else {
                            rm.status = 'success';
                            rm.title = this.$t('user_order.tips.submit_success');
                            rm.subTitle = res2.data.data.tracking_number;
                          }
                        } else {
                          rm.status = 'error';
                          rm.title = this.$t('user_order.tips.create_label_error');
                          rm.subTitle = null;
                          rm.error_message = res2.data.msg;
                        }
                      }).catch(e => {
                        rm.status = 'error';
                        rm.title = this.$t('user_order.tips.create_order_faild');
                        rm.subTitle = null;
                        rm.error_message = e.message;
                      });
                    };
                    createLabel();
                  } else {
                    rm.status = 'success';
                    rm.title = this.$t('user_order.tips.submit_success');
                    rm.subTitle = res.data.data.po_number;
                  }
                } else {
                  rm.status = 'error';
                  rm.title = this.$t('user_order.tips.create_order_faild');
                  rm.subTitle = null;
                  rm.error_message = this.$t(`${res.data.msg}`);
                }
              }).catch(e => {
                rm.status = 'error';
                rm.title = this.$t('user_order.tips.create_order_faild');
                rm.subTitle = null;
                rm.error_message = e.message;
              });
            }).catch((e) => {
              console.error(e)
            });
          }).catch((e) => {
            console.error(e);
            this.rates_result.ship_method_id = null;
          })
      },
      dropClick(key) {
        switch (key) {
          case 'next_one':
            reloadPageTab();
            break;
          case 'download':
            this.resultModal.download_loading = true;

            this.$http.post(`/user/order/label`, {
              ids: [this.form.id]
            }).then(res => {
              this.resultModal.download_loading = false;

              if (res.data.code === 0) {
                const blob = this.base64ToBlob(res.data.data, 'application/pdf');
                const fileName = `${this.form.po_number}.pdf`;

                if ("download" in document.createElement("a")) {
                  // 非IE下载
                  const el = document.createElement("a");

                  el.download = fileName;
                  el.style.display = "none";
                  el.href = URL.createObjectURL(blob);
                  document.body.appendChild(el);

                  el.click();

                  URL.revokeObjectURL(el.href);
                  document.body.removeChild(el);
                } else {
                  // IE10+下载
                  navigator.msSaveBlob(blob, fileName);
                }
              } else {
                if (res.data.msg.includes('OnlyURL')) {
                  const el = document.createElement("a");
                  el.href = res.data.extend.URL;
                  el.target = "_blank"
                  el.download = "label.pdf";
                  el.click();
                } else {
                  this.$message.error(`${res.data.msg}`);
                }
              }
            }).catch(e => {
              this.resultModal.download_loading = false;
              this.$message.error(e.message);
            });
            break;
          case 'back':
            this.resultModal.visible = false;
            this.$router.push('/user/order/order');
            break;
          case 'modity':

            this.resultModal.visible = false;

            if (Number(this.form.id) > 0) {
              this.$route.query = {};
              this.$route.query.id = this.form.id;
              reloadPageTab();

              this.isUpdate = this.$route.query.id !== undefined;

              this.$store.dispatch('theme/tabSetTitle', {
                fullPath: this.$route.fullPath,
                title: this.$t('share.update_title')
              });

              this.loadOrder(this.$route.query.id);
            }
            break;
          case 'void':
            this.resultModal.void_loading = true;

            if (Number(this.form.id) > 0) {
              this.$http.post('/user/order/void', {
                ids: [this.form.id]
              }).then(res => {
                this.resultModal.void_loading = false;

                if (res.data.code === 0) {
                  this.resultModal.visible = false;
                  this.$message.success(res.data.msg);

                  this.$router.push('/user/order/order');
                } else {
                  this.$message.error(res.data.msg);
                }
              }).catch(e => {
                this.resultModal.void_loading = false;
                this.resultModal.visible = false;
                this.$message.error(e.message);
              });
            } else {
              this.$router.push('/user/order/order');
            }
            break;
        }
      },
      /**
       * 添加箱
       */
      addPackage() {
        this.packages.push({
          id: `${(new Date()).getTime()}`,
          pieces: '1',
          packages_qty: '1',
          freight_class: '55',
          dims_unit: this.obj_all_unit.dims_unit,
          weight_unit: this.obj_all_unit.weight_unit,
          unit: this.obj_all_unit
        });
      },
      /**
       * 清空包裹
       */
      clearPackages() {
        this.form.packages = [];
        this.addPackage();
      },
      /**
       * 移除箱
       */
      removePackage(row) {
        if (this.packages.length > 1) {
          this.packages.splice(this.packages.indexOf(row), 1);

          this.$message.success('user_order.tips.delete_success');
        }
      },
      /**
       * 监视地址框粘贴地址自动识别转换
       */
      handlePaste(e) {
        var content = e.clipboardData.getData('text');
        var row = address.parse(content);

        this.$nextTick(() => {
          row && this.chooseAddress(row);
          !row && (this.address.name = content);
        });

        e.preventDefault();
      },
      /**
       * 选择地址
       */
      chooseAddress(row) {
        this.address.name = row.name;
        this.address.company_name = row.company_name;
        this.address.address1 = row.address1;
        this.address.address2 = row.address2;
        this.address.address3 = row.address3;
        this.address.city = row.city;

        // 根据规则渲染数据
        this.rules.state_code[0].disabled ? this.address.state_code = "" : this.address.state_code = row.state_code
        this.rules.postal_code[0].disabled ? this.address.postal_code = "" : this.address.postal_code = row
          .postal_code

        this.address.country_code = row.country_code;
        this.address.phone_number = row.phone_number;

        this.$refs.address_form.clearValidate();
        this.test_rates();
      },
      /**
       * 查询FBA地址
       */
      queryFbaAddress(name) {
        this.query_address_loading = true;

        this.$http.get(`/user/address_book/query_single_address/${name}`).then(res => {
          this.query_address_loading = false;

          if (res.data.code === 0) {
            this.chooseAddress(res.data.data);
          }
        }).catch(() => this.query_address_loading = false);
      },
      /**
       * 测试费用
       */
      test_rates: _.debounce(function(bul) {

        // 焦点控制开关
        if (!this.onFoucsControl) {
          return
        }
        // 控制按钮执行
        if (this.$setting.env == 'zad.lbl.morelink56.com' && !bul) {
          return false
        }

        if (!this.$refs.address_form || !this.$refs.packages_form) {
          return;
        }

        this.renderItemsData()

        if (this.validate_package) {
          return
        }
        if (test_rates_post.apiPost) {
          test_rates_post.cancel()
        }

        this.$refs.packages_form.validate();

        this.$refs.address_form.validate().then(() => {
          clearTimeout(this.rates_timer);
          this.packageMap();

          this.rates_timer = setTimeout(() => {
            this.rates_loading = true;
            this.rates_result = {};
            this.error_code = '';

            if (this.system_config.allow_customs_data != '1' || !this.allow_customs_data) {
              this.form.items = [];
            }

            const postData = Object.assign(this.form, {
              shipper_address_id: this.address.shipper_address_id,
              ship_method_id: this.rates_current_ship_method_id,
            });

            postData.shipper_address_id = !this.shipper ? (postData.shipper_address_id + "") : '0';
            postData.ship_date = moment().format('YYYY-MM-DD');

            console.log(postData);

            test_rates_post.apiPost = this.$http.post('/user/order/rates', postData, {
              cancelToken: test_rates_post.get_token()
            }).then(res => {
              this.rates_loading = false;

              if (res.data.code === 0) {
                this.rates_current_ship_method_id = res.data.data.ship_method_id;
                this.rates_result = res.data.data;

                var current = this.rates_result.ship_methods.find(a => a.id == this.rates_result
                  .ship_method_id);

                this.rates_result.postal_code_type = current != null ? current.postal_code_type : 0;
              } else {
                this.error_code = res.data.msg;
              }
            }).catch(e => {
              this.rates_loading = false;
              if (e.message == 'cancel') {
                return
              }
              this.$message.error(e.message);
            }).finally(() => {
              test_rates_post.new_token();
            })

          }, 500);
        }).catch(() => {});
        this.onFoucsControl = false;
      }, 300),
      /**
       * Rates排序
       */
      sort_rates(a, b) {
        return (a.active ? a.amount : 999999) - (b.active ? b.amount : 999999);
      },
      base64ToBlob(text, content_type) {
        var byteString = atob(text);
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var intArray = new Uint8Array(arrayBuffer);

        for (var i = 0; i < byteString.length; i++) {
          intArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([intArray], {
          type: content_type
        });
      },
      /**
       * items数据重组
       */
      renderItemsData() {
        this.form.items = this.items.filter(item => {
          const {
            unit: {
              declared_value_currency,
              manufacture_country_code
            }
          } = item;

          Object.assign(this.obj_all_unit, {
            item_weight_unit: item.weight_unit
          })

          Object.assign(item, {
            declared_value_currency,
            manufacture_country_code: this.$setting.env == 'mingda.lbl.morelink56.com' ? item
              .manufacture_country_code : manufacture_country_code,
            weight_unit: this.obj_all_unit.item_weight_unit
          });

          return item.description
        })
      }
    },
    setup() {
      const obj = cachePostCode();
      const form = itemHook();
      // 是否为修改状态
      const isUpdate = ref(false);
      form.stopUnitWatch();
      watch(
        form.obj_all_unit,
        (val) => {
          if (!isUpdate.value)
            localStorage.setItem('all_unit', JSON.stringify(val));
        }, {
          deep: true
        }
      )
      /* 邮编分析 */
      const analyzePostalCode = AnalyzePostalCode({
        address_form: form.address_form,
        address: form.address,
        rules: form.rules
      });

      return {
        ...obj,
        ...form,
        ...analyzePostalCode,
        isUpdate
      }
    }
  }
