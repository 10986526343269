
import {
    CalendarOutlined,
    InfoCircleOutlined
} from '@ant-design/icons-vue'

import {
    ref
} from "vue"
import axios from "axios"

export default {
    inject: ['onFoucsControl','test_rates'],
    components: {
        CalendarOutlined,
        InfoCircleOutlined
    },
    props: {
        model: Object,
        rules: Object,
        handleChangeDutyType: Function
    },
    computed: {
        form: {
            get() {
                return this.model
            }
        }
    },
    watch: {
        "model": {
            handler(val) {
                if (this.$setting.env == 'fhjy.lbl.morelink56.com'|| this.$setting.env =='first-star.lbl.morelink56.com') {
                    val.global_reference1 = this.$store.state.user.user.code ? 'FEC' + this.$store.state.user.user.code : ''
                }
            },
            deep: true
        }
    },
    methods: {
        validate() {
            return this.$refs.info_form.validate()
        },
        // 进口原因处理
        handleChangeReason(val) {
            localStorage.setItem("cache_reason_for_export", val);
        },
        change_test_rates(){
            this.onFoucsControl.value =true;
            this.test_rates()
        }
    },
    setup() {
        const importerList = ref([]);
        axios.get('/user/importer/simple_list').then(res => {
            if (res.data.code == 0) {
                importerList.value = res.data.data;
            }
        })

        return {
            importerList
        }
    }
}
